<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      :file-list="fileList"
      ref="ruleForm"
      label-width="140px"
      class="dialog-form-con"
    >
      <el-form-item label="应用名称" prop="navigateName">
        <el-input
          :disabled="ruleForm.isDefault==1"
          v-model="ruleForm.navigateName"
          style="width:350px;"
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="navigateIcon">
        <el-upload
          :action="url"
          list-type="picture-card"
          :accept="'.jpg, .jpeg, .png, .gif'"
          :limit="1"
          :before-upload="beforeUpload"
          :on-success="handleUploadSuccess"
          :file-list="fileList"
          style="width:350px;"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        label="链接"
        prop="navigateUrl"
        v-if="(row.isDefault == 2 && message == 'docEdit') || message == 'docAdd'"
      >
        <el-input v-model="ruleForm.navigateUrl" style="width:350px;"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="ruleForm.isEnable" style="width:350px;"></el-switch>
      </el-form-item>
      <el-form-item class="dialog-form-btn-cnt">
        <el-button type="primary" @click="submit('ruleForm')">确定</el-button>
        <el-button @click="closedialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["message", "orderNum", "row", "isShow", "type"],
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        navigateName: "",
        isEnable: true,
        navigateUrl: "",
        navigateIcon: "",
        isDefault: null,
      },
      rules: {
        navigateName: [
          { required: true, message: "请输入应用名称", trigger: "blur" },
        ],
        navigateUrl: [
          { required: true, message: "请输入链接", trigger: "blur" },
        ],
        navigateIcon: [
          { required: true, message: "请上传图标", trigger: "change" },
        ],
      },
      fileList: [],
      url: this.$global.baseURL + "/aliyun/oss/upload",
    };
  },
  watch: {
    isShow: {
      immediate: true,
      handler(newValue, oldValue) {
        this.fileList = [];
        console.log(newValue, this.message, this.type);
        if (newValue && this.message == "docAdd") {
          this.ruleForm = {};
        } else if (newValue && this.message == "docEdit") {
          this.ruleForm = this.row;
          this.ruleForm.isEnable = this.row.isEnable == 1 ? true : false;
          if (this.row.navigateIcon) {
            this.fileList.push({
              url: this.row.navigateIcon,
            });
          }
          console.log(this.ruleForm);
        }
      },
    },
  },
  methods: {
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过10MB");
      }
    },
    handleUploadSuccess(response, file, fileList) {
      console.log(this.fileList);
      this.ruleForm.navigateIcon = file.response.result.filePath;
    },

    close() {
      this.$emit("closeDialog");
    },
    //关闭弹窗
    closedialog() {
      this.$emit("quxiao");
    },
    submit(formName) {
      const params = { ...this.ruleForm };

      params.teamsId = JSON.parse(localStorage.currentTeam).id;

      if (params.isEnable) {
        params.isEnable = 1;
      } else {
        params.isEnable = 2;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.message == "docAdd") {
            params.type = this.type;
            params.isDefault = 2;
            params.orderNum = this.orderNum + 1;

            console.log(params);
            this.$http({
              url: this.$http.adornUrl("/teamNavigate/add"),
              method: "post",
              params,
            }).then((res) => {
              if (res.data.status) {
                this.$message({
                  type: "success",
                  message: "成功",
                  duration: 1500,
                  onClose: () => {
                    this.close();
                  },
                });
              }
            });
          } else if (this.message == "docEdit") {
            if (this.row.isDefault == 1 || this.row.isDefault == null) {
              delete params.navigateUrl;
            }
            this.$http({
              url: this.$http.adornUrl("/teamNavigate/update"),
              method: "post",
              params,
            }).then((res) => {
              if (res.data.status) {
                this.$message({
                  type: "success",
                  message: "成功",
                  duration: 1500,
                  onClose: () => {
                    this.close();
                  },
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>